.sidebar {
  margin-top: 10px;
  width: 250px;
  padding: 10px;
  background-color: #f4f4f4;
  border-right: 1px solid #ddd;
}

.inputContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.results {
  margin-top: 10px;
}

.resultItem {
  padding: 8px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.resultItem:hover{
  font-weight: bold;
  cursor: pointer;
}

.noResults {
  padding: 8px;
  color: #999;
}


@media (max-width: 1024px) {
  .sidebar {
    margin: 10px auto;
  }
}

@media (max-width: 768px) {
  .sidebar {
    margin: 10px auto;

  }
}

@media (max-width: 480px) {
  .sidebar {
    margin: 10px auto;

  }
}
