.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1220px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.error {
 background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
}

.modalHeader {
  text-align: center;
  margin-bottom: 20px;
}

.headerContainer,
.formFields,
.section {
  margin-bottom: 20px;
}

.userInfoContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
}

.column {
  flex: 1 1 calc(25% - 10px);
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  overflow-wrap: break-word;

}

.column:not(:first-child) {
  border-left: 1px solid black;
}

.titleField {
  width: 100%;
  background-color: #A9A9A9;
  padding: 20px;
  margin-bottom: 5px;
  text-align: center;
  box-sizing: border-box;
}

.displayField {
  background-color: #FFFFFF;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
}

.textarea {
  width: 90%;
  padding: 10px;
  margin: 10px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit {
  display: flex;
  justify-content: space-between;
}

.cancelButton,
.submitButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancelButton {
  background: #ddd;
}

.submitButton {
  background: #007bff;
  color: white;
}

.formFields {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

.formColumn {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
}

.inputField {
  background-color: #FFFFFF;
  padding: 20px;
  margin: 20px;
  border: 1px solid #A9A9A9;
}

.labelTitle {
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
}

.disabled {
  cursor: not-allowed;
  background: #868686;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    max-height: calc(100vh - 20px);
    padding: 15px;
  }

  .textarea {
    width: 100%;
  }

  .submit {
    flex-direction: column;
  }

  .displayField {
    padding: 15px;
    font-size: 12px;
  }
}