.solicitudContainer {
  font-family: "Arial", sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inputContainer {
  background-color: #c7d6dc;
  margin-bottom: 0 !important;
  border: 1px solid black;
}

.container {
  padding: 20px;
}

.form_data {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form_data div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form_data label {
  flex: 1;
  margin-right: 20px;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.form_data input,
select {
  flex: 2;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
}

.form_data input:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

.errorInput {
  border: 2px solid red;
}

.important {
  color: #ff0000;
  font-weight: bold;
  margin-bottom: 20px;
}

.bold {
  font-weight: bold;
  margin-bottom: 20px;
}

.titulo {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
  color: #007bff;
}

.error {
  padding: 15px;
  font-size: large;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.385);
  color: white;
}

.iconContainer {
  background-color: #00c2c7;
  padding: 5px;
  margin-left: 10px;
  border-radius: 20%;
  display: inline-block;
}

.customIcon {
  color: white;
  font-size: 20px;
}

.btn {
  font-weight: bold;
  font-size: 16px;
  width: 150px;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: blue;
  /* Color original del botón */
  color: aliceblue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: darkblue;
  /* Oscurecer el azul en hover */
}

.submit {
  display: flex;
  justify-content: center;
}

.cancelar {
  background-color: blue;
  /* Mismo color de los botones */
}

.cancelar:hover {
  background-color: darkblue;
  /* Mantén consistencia al oscurecer */
}

.enviar {
  background-color: blue;
  /* Mismo color de los botones */
}

.enviar:hover {
  background-color: darkblue;
  /* Mantén consistencia al oscurecer */
}

.tooltipIcon {
  margin-left: 8px;
  position: relative;
  cursor: pointer;
}

.tooltipText {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  width: 500px;
}

.tooltipIcon:hover .tooltipText {
  visibility: visible;
  opacity: 0.9;
}


.tableHeader {
    background-color: #c7d6dc;
    border: 1px solid #9E9E9E;
    margin-bottom: 0 !important;
    align-items: center;
}

.textarea {
  width: 100%;           
  max-width: 100%;     
  height: 180px;        
  padding: 10px;       
  font-size: 16px;      
  line-height: 1.6;      
  resize: vertical;  
  margin-bottom: 20px;    
}