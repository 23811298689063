.formCargarEvidencia {
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.containerInput {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: none;
    width: 100%;
}

.inputFile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    border: none;
    width: 100%;
}

.containerInput:hover {
    /* opacity: 0.8; */
    cursor: pointer;
}
.inputFile:hover {
    cursor: pointer;
}

.btnCargarEvidencia {
    background-color: transparent;
    border: none;
}

.btnCargarEvidencia:hover {
    cursor: pointer;
}

.inputSubirEvidencia {
    padding: 8px 10px;
    color: #ffff;
    background-color: #054BDD;
    border-radius: 4px;
}
.inputSubirEvidencia:hover {
    cursor: pointer;
    background-color: #3f5ac5;
}