.table {
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}

.table th {
  font-weight: bold;
}

.clickableRow {
  cursor: pointer;
  background-color: #f9f9f9;
}

.clickableRow:hover {
  background-color: #e6f7f7;
}

.instruccionesTitle {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #bfbfbf;
}

.button {
  padding: 8px 12px;
  background-color: white;
  color: #0075FF;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.button:hover {
  background-color: #c6ddf7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.button:disabled {
  cursor: not-allowed;
}

.buttonChanged {
  border: 2px solid red;
}

.buttonDisabled {
  cursor: not-allowed;
}

.buttonChanged {
  border: 2px solid red; /* Borde rojo para indicar que hubo una actualización */
}