.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.generateButton {
    background-color: #054BDD;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;
    text-transform: uppercase;
}


.uploadSection {
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin-top: 20px;
}

.uploadSection h2 {
    font-size: 18px;
    margin-bottom: 20px;
}

.uploadRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.label {
    flex: 1;
    font-size: 16px;
    text-align: left;
}

.fileInput {
    flex: 2;
    padding: 5px;
}

.uploadButton {
    flex: 1;
    background-color: #054BDD;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
}

.uploadButton:hover {
    background-color: #3d91e6;
}
.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  background-color: transparent;
}

.buttonPdf:hover {
  cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
}
.generateButton:disabled,
.uploadButton:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}