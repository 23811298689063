.searchAndMunicipio {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .searchInputContainer {
    flex: 1;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  .municipioFilterContainer {
    flex: 1;
    text-align: right;
    margin-bottom: 40px;
  }
  .searchInput {
    width: 300px;
    font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
  }
  
  .searchLabel {
    font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
  }
      
  