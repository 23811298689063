.container {
  font-family: "Arial", sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.inconformidad_title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: #443f3f;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  background-color: #f0f0f040;
  font-family: "Sarabun", sans-serif, 'EBGaramond', serif;

}

@media (max-width: 768px) {
  .aside {
      display: none;
  }
}

/* Containers */
    
  .container_aside {
    display: flex;
    border: 1px solid #B9B9B9;
    border-style: none dashed none none;
}

.solicitudContainer {
    width: 450px;
    margin-left: 20px;
    padding: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  

  /* estilo para form */

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

  }

.label {
    flex: 1;
    margin-right: 10px;
    font-weight: bold;
  }

  /* Checkbox */
  .checkboxes {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  /* Fileinput */
  .inputFile {
    flex: 2;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
  }

  /* Radio button */

  .radioButtons {
    flex: 2;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: center;
  }

  .radioButtons label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    font-size: 16px;
    position: relative;
  }

  /* .radioButtons input[type="radio"] {
    display: none;
  }
  
  .radioButtons input[type="radio"] + label::before {
    content: "";
    width: 20px; 
    height: 20px; 
    border: 2px solid #ccc; 
    border-radius: 50%;
    display: inline-block;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  .radioButtons input[type="radio"]:checked + label::before {
    background-color: #0D6EFD;
    border-color: #0D6EFD;
    position: relative;
  }
  
  .radioButtons input[type="radio"]:checked + label::after {
    content: "✔"; 
    color: white;
    font-size: 14px; 
    position: absolute;
    left: 5px; 
    top: 2px;
  } */
 
/* Selectfields */

.select,
.input {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

.btn {
    font-weight: bold;
    font-size: 16px;
    width: 120px;
    margin: 20px;
    margin-right: 0px;
    padding: 10px 20px;
    background-color: #0D6EFD;
    color: aliceblue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn:disabled{
  background-color: #6C757D;
  color: white;
  cursor: not-allowed;
}


.submit {
    display: flex;
    justify-content: end;
}

.aside {
    width: 15%;
    padding: 1rem;
    border-right: 1px dashed #b9b9b9;
    background-color: #f5f5f5;
}  

.aside > p {
    margin: 8px 0;
}

.formTitle{
    font-weight: bold;
    font-size: 1.1rem;
    margin-bottom: 5px;
}