.selectDep {
    width: 100%; 
    height: 40px; 
    padding: 10px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    background-color: #fff;
    position: relative; 
    z-index: 1; 
  }
  
  .selectDep option {
    padding: 10px; 
    background-color: #fff; 
    z-index: 10; 
    position: relative;
  }