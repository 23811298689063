@font-face {
  font-family: 'Serotiva';
  src: url('../../../../assets/fonts/Serotiva-Bold-BF663985088f8d7.otf') format('opentype');
}

.footer {
  background-color: #4b4b4b;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  font-family: 'Serotiva', sans-serif;
}

.logo_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 30px;
}

.udg_logo {
  width: 230px;
  height: 70px;
}

.footer_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
}

.footer_info {
  flex: 1;
  min-width: 280px;
  margin: 10px 25px;
}

.footer_contact {
  flex: 1;
  min-width: 280px;
  margin: 0 20px;
  text-align: end;
  align-content: center;
}

.footer_info h4,
.footer_contact h4 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: white;
}

.footer_info p,
.footer_contact p {
  font-size: 1rem;
  margin: 5px 0;
  color: #ffffff;
}

.icon {
  margin-right: 10px;
  color: #ffcc00;
}

@media (max-width: 768px) {
  .footer_content {
    flex-direction: column;
    align-items: center;
  }

  .footer_info,
  .footer_contact {
    margin-bottom: 20px;
  }
}

.social {
  width: 20px;
  filter: brightness(0) invert(1);
}

.socialSpacing {
  position: relative;
  top: -3px;
  font-size: 25px;
  margin: 0 8px;
  filter: brightness(0) invert(1); 
}

.copyright {
  text-align: center;
  font-size: 10px;
}