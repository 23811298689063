.container {
  margin: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  background-color: transparent;
}

.buttonPdf:hover {
  cursor: pointer;
}

.btnDescargar {
  background-color: #054BDD;
  color: white;
  width: 20%;
  border: none;
  border-radius: 4px;
  padding: 8px 0;
}

.btnGenerarDoc {
  padding: 9px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #054bdd;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.btnEnviarCotejo {
  padding: 9px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #054bdd;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.btnDescargar:hover {
  cursor: pointer;
  background-color: #3d91e6;
}
.btnDescargaDisabled {
  background-color: #ccc;
  cursor: not-allowed;
  width: 20%;
  border: none;
  border-radius: 4px;
  padding: 8px 0;
}

.btnDescargaDisabled {
  background-color: #ccc;
  cursor: not-allowed;
  width: 20%;
  border: none;
  border-radius: 4px;
  padding: 8px 0;
}

.uploadSection {
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin-top: 20px;
}

.uploadSection h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.uploadRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  flex: 2;
  font-size: 15px;
  text-align: left;
}

.fileInput {
  flex: 2;
  padding: 5px;
}

.uploadButton {
  flex: 1;
  background-color: #054BDD;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

.uploadButton:hover {
  background-color: #3d91e6;
}

.uploadButton:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.modalDiv{
  display: flex;
  justify-content: end;
}