.tablaResultados {
    margin-top: 2rem;
}

.rowHeadTable > th {
    text-align: center;
}

.tdCalificacion, .tdTextCenter {
    text-align: center;
}

.rowHeadTh > tr > td {
    padding: 5px;
}

.bodyTable > tr > td {
    padding: 5px;
}

.tdNombreCriterio {
    padding-left: 2rem!important;
}

.rowFooterTable {
    background-color: #a9a9a9;
}

.tdTotal {
    text-align: right;
    font-weight: bold;
}
