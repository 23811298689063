.selectContainer {
  position: relative;
  width: 100%;
  /* width: 200px; */
}

.selectBox {
  background-color: white;
  border: 1px solid #ccc;
  /* padding: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 1;
  display: flex;
  min-width: max-content;
  gap: 10px;
  justify-content: space-between;
}

.optionsContainer {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f0f0f0;
}

.selectContainer.disabled {
  opacity: 0.6;
  pointer-events: none; /* Desactiva la interacción */
}

.selectBox {
  cursor: pointer;
}

.selectBox:disabled {
  cursor: not-allowed;
}

