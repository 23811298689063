.formContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  /* Input field for ciclo */
  .inputField {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 40px;
    /* height: 19px; */
  }

  .inputField label {
    color: black;
    font-weight: bold;
    text-align: left;
    margin-right: 10px;
  }
  
  .input {
    display: block;
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #898989;
  }
  
  .input:focus {
    border-color: #898989;
    outline: none;
  }
  
  .input::placeholder {
    color: #898989;
  }
  
  input:disabled {
    cursor: not-allowed;
    color: #898989;
  }
      /* estilo para btn */
  
      .submit {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items:center;
        height: 100%;
        margin: 20px;
        margin-right: 0px;
      }
      
      .btn {
        padding: 10px 20px;
        background-color: #0d6efd;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
      
      .btn:disabled {
        background-color: #6c757d;
        color: #ffffff;
        cursor: not-allowed;
      }
      