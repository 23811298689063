.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.tableContainer {
    max-height: 300px;
    overflow-y: auto;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.total {
    font-weight: bold;
    text-align: left;
    margin-top: 30px;
}

.closeButton {
    display: block;
    margin-left: auto;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #0D6EFD;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.closeButton:hover {
    background-color: #375179;
}