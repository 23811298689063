/* width */
.formEvaluacion::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.formEvaluacion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #807b7b;
  border-radius: 2px;
  margin: 5px;
}

/* Handle */
.formEvaluacion::-webkit-scrollbar-thumb {
  background: #4B4B4B;
  border-radius: 5px;
}

/* Handle on hover */
.formEvaluacion::-webkit-scrollbar-thumb:hover {
  background: #807b7b;
}

.formEvaluacion {
  border: 2px solid #A9A9A9;
  border-radius: 3px;
  padding: 10px;
  height: 555px;
  overflow-y: auto;
}

.formPregunta:nth-child(even) {
  margin: 10px 0;
}

textarea {
  border-radius: 4px;
  border: 1px solid #CED4DA;
}

.restForm {
  margin-top: 1rem;
}

/* .container_inputs_select_radio {} */

.inputSelect {
  width: 40%;
}

.inputRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1rem 0;
}

.inputRadio label,
.textarea label {
  font-weight: 400;
  font-size: 14px;
  color: #212529;
}

.textarea textarea {
  margin-top: 10px;
  height: 7rem;
  width: 100%;
}

.submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
}

.submit input[type="submit"] {
  padding: 8px 10px;
  color: #ffff;
  background-color: #054BDD;
  border-radius: 4px;
}

.submit input[type="submit"]:hover {
  cursor: pointer;
  background-color: #3f5ac5;
}

.submit > p {
  color: #00C0A9;
  margin: 10px 0;
}