.container {
  padding: 20px;
}

.containerInfo {
  width: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  gap: 10px;
}

.title {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #9f9f9f;
}

.textAlert {
  text-align: center;
  color: rgb(185, 0, 0);
  font-weight: bold;
}

@media (max-width: 768px) {
  .containerInfo {
    grid-template-columns: 1fr;
  }
}
