.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    width: 500px;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    
  }
  
  .headerTitle {
    font-size: 1.5rem;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .content {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .cancelButton {
    background: #6c757d;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .okButton {
    background: #28a745;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  