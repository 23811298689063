.login_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%; /* Ensure width matches both containers */
  padding: 0px 5px;
  background-color: white;
  color: #333;
  margin: 0px auto;
  border: 1px solid #0943e2;
}

.login_container_head {
  background-color: #0943e2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 65%;
  padding: 10px 6px;
  margin: 0 0; /* Center the header as well */
  color: white; /* Assuming text is white on the dark background */
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-direction: column;
}
.error{
  padding: 15px;
  font-size: large;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.385);
  color: white;
  text-align: justify;
}

.contacto {
  color: white;
  text-align: center;
}

.button {
  margin-top: 30px; /* Ajusta la distancia superior para que esté más cerca del input */
  background-color: #0943e2; /* Color del botón */
  padding: 5px 20px; /* Ajusta el tamaño del botón */
  border-radius: 5px; /* Bordes redondeados */
  color: white;
  font-weight: bold;
  font-size: 1rem; /* Tamaño del texto dentro del botón */
  text-align: center; /* Asegura que el texto esté centrado */
  border: none;
  cursor: pointer;
  width: 100%; /* Hace que el botón ocupe todo el ancho del formulario */
  max-width: 100px; /* Limita el ancho máximo del botón */
  margin-bottom: 20px;
}

.button:hover {
  background-color: #3d91e6; /* Color al pasar el mouse por encima */
}

.button-container {
  display: flex;
  justify-content: center; /* Centra el botón dentro de su contenedor */
}


form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}
.form-group {
  display: flex;
  align-items: center; /* Vertically center label and input */
  justify-content: space-between; /* Distribute space between label and input */
  width: 100%; /* Ensure it takes full width */
  margin-bottom: 15px; /* Add some space between each row */
}
.formplace {
  width: 100%;
  display: flex;
  flex-direction: column; /* Asegura que el input y el placeholder estén en columna */
}
.placeholder {
  font-size: 14px;
  color: #bbbacb;
  margin-top: 5px; /* Espacio entre el input y el placeholder */
  text-align: center; /* Alinea el placeholder a la izquierda */
}
.input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}



.label {
  flex-basis: 30%; /* Label takes 30% of the width */
  margin-right: 10px;
  margin-top: 0px;
  font-weight: bold;
  color: black;
  text-align: left; /* Right-align the label */
}


.input {
  margin-top: 20px;
  flex-basis: 100%; /* Input takes 70% of the width */
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #e3ecf6;
  height: 10px; 
  transition: border-color 0.3s ease;
}

input:focus {
  border-color: #19357b;
  outline: none;
}

.login_text {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000000;
  font-size: 1rem;
}

.text {
  text-align: center;
  font-weight: bold;
  margin: 40px 0;
  font-size: xx-large;
}
.textlogin {
  text-align: center;
  font-weight: bold;
  align-items: center;
  margin: 0px 0;
  font-size: 16px;
  color: white;
}

.email_info {
  text-align: center;
  margin: 20px 0;
}

.fontxl {
  font-size: xx-large;
}
.infoSection {
  margin-top: 40px;
  text-align: center;
}
.contactSection {
  margin-top: 40px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #c7d6dc;
  width: 100%;
}

.infoText {
  font-size: 18px;
  color: #47464b;
  font-weight: bold;
}

.infoButton {
  display: inline-block;
  background-color: #00bfa6;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 0px;
  font-weight: bold;
}

.contactInfo {
  font-size: 14px;
  margin-top: 20px;
  color: #47464b;
  font-weight: bold;
}
.contactInfo a{
  color: #47464b;
  font-weight: bolder;
  text-decoration: none;
}

.browserCompatibility {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #47464b;
}


@media (max-width: 1024px) {
  .login_container {
    padding: 5% 8%;
    margin: 30px auto;
  }

  .login_text {
    font-size: 0.9rem;
  }

  .text {
    font-size: 1.2rem;
    margin: 30px 0;
  }

  .email_info {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .login_container {
    padding: 6% 10%;
    margin: 20px auto;
  }

  .login_text {
    font-size: 0.8rem;
  }

  .text {
    font-size: 1rem;
    margin: 20px 0;
  }

  .email_info {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .login_container {
    padding: 8% 12%;
    margin: 10px auto;
  }

  .login_text {
    font-size: 0.7rem;
  }

  .text {
    font-size: 0.9rem;
    margin: 10px 0;
  }

  .email_info {
    margin: 10px 0;
  }
}
