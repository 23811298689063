.btnDelete {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}

.conditionRowSelect {
  width: 100%;
}
