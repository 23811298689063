.alert {
  padding: 5px;
  background-color: #f44336;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}

.alert_danger{
    background-color: #ff6b60;
}

.alert_success{
    background-color: #60dc64;
}
