.container {
  font-family: "Arial", sans-serif;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.inconformidad_title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: #443f3f;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  background-color: #f0f0f040;
  font-family: "Sarabun", sans-serif, 'EBGaramond', serif;    
}

.configContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
}

.row {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.labelTxtArea {
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 313px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.select {
  width: 300px;
  padding: 10px;
}

.input {
  width: 600px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #898989;
}

.textArea {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #898989;
  height: 120px;
  margin-left: 313px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.submitButton {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Cambios Responsivos */
@media (max-width: 768px) {
  .row {
    flex-direction: column;
    gap: 0.5rem; 
  }

  .labelTxtArea,
  .textArea {
    margin-left: 0; 
  }

  .select {
    width: 100%;
  }

  .input {
    width: 100%;
  }

  .textArea {
    width: 100%;
  }

  .buttonContainer {
    margin-top: 1rem; 
  }
}

@media (max-width: 480px) {
  .inconformidad_title {
    font-size: 1rem;
  }

  .input {
    width: 100%;
    padding: 8px;
  }

  .textArea {
    width: 100%;
    height: 100px;
    padding: 8px;
  }

  .submitButton {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}
