.tablePlan {
    border-collapse: collapse;
    width: 100%;
    text-align: center !important;
}

.tablePlan th,
.tablePlan td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center !important;
    vertical-align: middle;
}

.tablePlan th {
    font-weight: bold;
    background-color: #f2f2f2;
    text-align: center !important;
}

.tablePlan thead tr:nth-child(1) th {
    background-color: #d9d9d9;
}

.tablePlan thead tr:nth-child(2) th {
    background-color: #e6e6e6;
}


.statusApproved {
    color: green;
}

.statusRejected {
    color: red;
}

.statusPending {
    color: orange;
}

.button {
    padding: 8px 12px;
    background-color: white;
    color: #0075FF;
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
}

.button:hover {
    background-color: #c6ddf7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.button:disabled {
    cursor: not-allowed;
}

.buttonChanged {
    border: 2px solid red; /* Borde rojo para indicar que hubo una actualización */
  }
  
.instruccionesTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #bfbfbf;
  }