.modal {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 200px;
  }

  .modal.show {
        display: block;
    }

  .modalContent {
    text-align: center;
  }

  .title{
    font-size: 20px;
    margin-bottom: 10px;
    color: green;
  }


  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #aaa;
    font-size: 20px;
    cursor: pointer;
  }