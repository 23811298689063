.container {
    padding: 20px;
    font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .inputGroup {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-right: 10px;
  }
  .button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .resultContainer {
    margin-top: 20px;
    overflow-x: auto;
  }
  
  .successMessage {
    color: green;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .errorContainer {
    margin-top: 20px;
    text-align: center;
    color: red;
  }
  
  .errorTitle {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .errorMessage {
    font-size: 1em;
  }
  
  