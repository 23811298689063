.container {
    padding: 5px;
}

.head_evaluacion {

}

.container_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #054BDD;
    padding: 6px 12px;
    color: #ffff;
    height: 30px;
}
.container_head > p {
    font-size: 18px;
}

.container_head_buttons > button {
    border: none;;
    background-color: transparent;
    cursor: pointer;
}

.data_nombre {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 10px 0;
}
.data_nombre > p > span {
    text-transform: uppercase;
}



