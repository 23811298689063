.container {
    font-family: "Arial", sans-serif;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .inconformidad_title {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      margin: 10px;
      color: #443f3f;
      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      background-color: #f0f0f040;
      font-family: "Sarabun", sans-serif, 'EBGaramond', serif;    
    }

    .subTitle {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      margin: 20px 0px;
      color: #443f3f;
      font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
    }

    /* header */

    .header {
      display: flex;
      justify-content: space-between; 
      align-items: center;
    }

    .btnContainer{
      display: flex;
      justify-content: end; 
      align-items: center;
      margin: 20px;
      margin-right: 0px;
    }

    .btn {
      padding: 10px 20px;
      background-color: #0d6efd;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .btn:disabled {
      background-color: #6c757d;
      color: #ffffff;
      cursor: not-allowed;
    }
    
  
