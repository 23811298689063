/* RoleSelectionModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
}

.modalTitle {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.select {
    width: 100%;
    padding: 8px;
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0943e2;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.submitButton:disabled {
    background-color: #ccc;
}