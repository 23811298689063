.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.txtCenter{
  text-align: center !important;

}
.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.table th:first-child,
.table td:first-child {
  width: 5%; /* Reduce el ancho de la columna del checkbox */
  text-align: center;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 30%; /* Reduce el ancho de la columna de nombre */
}

.table th:nth-child(3),
.table td:nth-child(3),
.table th:nth-child(4),
.table td:nth-child(4) {
  width: 15%; /* Reduce el ancho de las columnas de fechas */
}
.pagination {
  text-align: center;
  margin: 20px 0;
}

.pagination button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination button:hover:not(:disabled) {
  background-color: #007bff;
  color: #ffffff;
}

.pagination span {
  margin: 0 15px;
  font-size: 1rem;
}


.submitButton {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #198754;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.submitButton:hover {
  background-color: #157347;
}
.subTitle {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 20px 0px;
  color: #443f3f;
  font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
}