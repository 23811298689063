.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #cccccc40;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.field_select {
  position: relative;
}

.filterRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.field_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  width: max-content;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid #cccccc40;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.field_label {
  font-size: 0.8rem;
  letter-spacing: 0.4px;
  width: max-content;
  text-shadow: 0.5px 0.5px 0.5px #00000040;
  font-family: "Sarabun", sans-serif, "EBGaramond", serif;
}

.constancia_title {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin: 10px;
  color: #443f3f;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
  background-color: #f0f0f040;
  font-family: "Sarabun", sans-serif, "EBGaramond", serif;
}

.container {
  padding: 10px;
  font-family: "Sarabun", sans-serif, "EBGaramond", serif;
  overflow-x: auto;
}
.link {
  color: #007bff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.link:hover {
  text-decoration: underline;
}
.marginBottom {
  margin-bottom: 20px;
}

.button {
  padding: 8px 12px;
  background-color: white;
  color: #0075ff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}
.button:hover {
  background-color: #c6ddf7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.button:disabled {
  cursor: not-allowed;
}

.buttonChanged {
  border: 2px solid red; /* Borde rojo para indicar que hubo una actualización */
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
}