.encabezadoNivel0 {
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  margin: 15px 0;
}

.encabezadoNivel2 {
  display: flex;
  gap: 5px;
  align-items: center;
}

.encabezadoNivel1,
.encabezadoNivel2,
.encabezadoNivel3,
.encabezadoNivel4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 8px 0;
}

.encabezadoNivel1 {
  font-size: 18px;
  margin: 8px 0;
}

.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  background-color: transparent;
  width: auto;
}

.buttonPdf:hover {
  cursor: pointer;
}

.formPregunta:nth-child(even) {
  margin: 10px 0;
}

.containerRespuestas {
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding-left: 20px;
  margin-top: 8px;
}

.respuesta {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: left;
  width: 100%;
}

.respuestaLabel {
  font-weight: 400;
  font-size: 14px;
  display: block;
  text-align: left;
  color: #000000;
  width: auto;
}

.inputText {
  border: 1px solid #B7B7B7;
  border-radius: 4px;
  width: 40px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  padding: 13px 2px;
}

.inputText:read-only {
  opacity: 0.5;
  cursor: not-allowed;
}
.inputRadio {
  border: 1px solid #B7B7B7;   
  border-radius: 4px;
  width: 40px;
  height: 25px;
  font-size: 14px;
  text-align: center;
  padding: 13px 2px;
}

.containerBtnPDF {
  display: flex;
  justify-content: center;
}

.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
}

.buttonPdf:hover {
  cursor: pointer;
}
.buttonPdf > svg {
  height: 100%;
  width: 100%;
}
