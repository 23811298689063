.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.filterGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  min-width: 200px;
}

.label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.input,
.select {
  width: 100%;
  max-width: 300px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.input:focus,
.select:focus {
  border-color: #007bff;
  background-color: #eef5ff;
  outline: none;
}

@media (max-width: 768px) {
  .filterContainer {
    flex-direction: column;
    align-items: stretch;
  }

  .filterGroup {
    width: 100%;
    align-items: center;
  }

  .input,
  .select {
    max-width: 100%;
  }
}