.containerBtnPDF {
  display: flex;
  justify-content: center;
}

.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
}

.buttonPdf:hover {
  cursor: pointer;
}
.buttonPdf > svg {
  height: 100%;
  width: 100%;
}  

.tdEliminarEvidencia {
  text-align: center;
}

.tdEliminarEvidencia>button {
  margin-right: 12px;
}

.buttonPdf {
  border: none;
  padding: 5px;
  border-radius: 4px;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
}

.buttonPdf:hover {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.label {
  font-weight: bold;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.label {
  font-weight: bold;
}

.fullRow {
  font-weight: bold;
  background-color: #f0f0f0; /* Color de fondo */
  padding: 10px; /* Espaciado interno */
  text-align: center; /* Centrar el texto */
}