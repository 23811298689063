/* width */
.formEvaluacion::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.formEvaluacion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #807b7b;
  border-radius: 2px;
  margin: 5px;
}

/* Handle */
.formEvaluacion::-webkit-scrollbar-thumb {
  background: #4b4b4b;
  border-radius: 5px;
}

/* Handle on hover */
.formEvaluacion::-webkit-scrollbar-thumb:hover {
  background: #807b7b;
}

.formEvaluacion {
  border: 2px solid #a9a9a9;
  border-radius: 3px;
  padding: 10px;
  height: 555px;
  overflow-y: auto;
}

.formPregunta:nth-child(even) {
  margin: 10px 0;
}

textarea {
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.restForm {
  margin-top: 1rem;
}

/* .container_inputs_select_radio {} */

.inputSelect {
  width: 40%;
}

.inputRadio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 1rem 0;
}

.inputRadio label,
.textarea label {
  font-weight: 400;
  font-size: 14px;
  color: #212529;
}

.textarea textarea {
  margin-top: 10px;
  height: 7rem;
  width: 100%;
}

.submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
}

.submit button {
  border: none;
  padding: 8px 10px;
  color: #ffff;
  background-color: #054bdd;
  border-radius: 4px;
}

.submit button:hover {
  cursor: pointer;
  background-color: #3f5ac5;
}



.submit button:disabled {
  background-color: #7a8cbc;
  cursor: not-allowed;
}

.submit > p {
  color: #00c0a9;
  margin: 10px 0;
}

.inputSelect select:disabled {
  appearance: none;
  background-color: transparent;
  color: inherit;
  border: 1px solid #ced4da;
  cursor: not-allowed;
  opacity: 1;
}

.inputSelect select:disabled:hover {
  cursor: not-allowed;
}
