.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.textoAdvertencia {
    text-align: center;
    color: #C50D39;
    font-weight: 500;
}
.textoAdvertencia > .br {
    display: block;
}

.containerButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.buttonAceptar {
    background-color: #054BDD;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
    padding: 8px 15px;
    border-radius: 4px;
    border: none;
}
.buttonAceptar:hover {
    cursor: pointer;
    background-color: #3f5ac5;
}

.cancelarButton {
    background-color: #6c757d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}