

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  table th, table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }

  table th {
    background-color: #a9a9a9;
    color: #000;
  }


  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 20px; 
    margin-top: 20px;
  }
  

  
  .optionButton i {
    font-size: 24px;
  }
  
  .optionButton {
    background-color: #0057d9;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  
  .optionButton:hover {
    background-color: #0041a3;
  }
  

  .searchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  label {
    margin-right: 10px;
    font-weight: bold;
  }
  
  .searchInput {
    margin-left: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: px;
  }
  
  .searchIcon {
    margin-left: -30px; 
    font-size: 18px;
    border: none;
    background-color: #c6d5da;
    color: #0942e1;
  }
  .searchIcon:hover {
  
    background-color: #bac7cc;
    
  }
  
  .searchText {
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .searchSelect {
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
  }
  
  .buttonEdit
  {
    display: inline-block;
    color: #00c2c7;
    font-size: 30px; 
    background: none;
    border: none;
    cursor: pointer;
  }

  .buttonEdit:hover
  {
    color: #01adb3;

  }

  .numberIcon
  {
    color: #000;
    background-color: #f9c253;
    padding: 10px;
    align-items: center;
    align-self: center !important;
    margin-left: 5px;
    border-radius: 10%;
  }