.searchContainer {
  position: relative;
  display: flex;
  Justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  border: 1px solid #ccc;
  font-size: 14px;
}

.searchInput {
  width: 100%;
  /* padding: 8px; */
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  border: none;
  margin: 0;
  padding: 8px;
}

.searchButton {
  /* margin-top: 8px; */
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #0056b3;
}

.suggestionsList {
  position: absolute;
  /* top: 100%; */
  top: 40px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.suggestionItem {
  padding: 8px;
  cursor: pointer;
}

.suggestionItem:hover {
  background-color: #f1f1f1;
}
