.titulo {
    font-size: 18px;
    color: #054BDD;
}

.tableHeader {
    background-color: #c7d6dc;
    border: 1px solid #9E9E9E;
    margin-bottom: 0 !important;
    align-items: center;
}

.important{
    list-style: none;
    color: #ff0000;
    font-weight: bold;
    
}

.tituloContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 15px;
    margin-top: 30px;

}

.textBlack {
    color: black;
}