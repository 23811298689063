.aside {
    width: 100%;
    height: 100%;
    color: #47464C;
}

.aside > p {
    margin: 8px 0;
}

.list_instrucciones > li {
    margin: 10px 0;
}