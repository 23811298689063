.containerModalEliminarEvidencia {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.textoAdvertencia {
    color: #DE3323;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0;
}
.textoAdvertencia > span {
    text-align: justify;
}

.formEliminarEvidencia {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: center;
    gap: 10px;
    padding: 5px;
}

.btnEliminarEvidencia {
    background-color: #F73927;
    color: #FFFFFF;
    width: 107px;
    height: 38px;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.btnEliminarEvidencia:hover {
    cursor: pointer;
    background-color: #FF876F;
}