  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    }

    .fullWidth {
      width: 100%; 
    }

    .inputGroup {
      display: flex;
      gap: 20px; 
      align-items: center;
    }

    .inputGroup > input, .inputGroup > .switchField {
      flex: 1; 
    }

    .inputGroup > input {
      flex: 4;
    }

    .switchField {
      flex: 1; 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
    .switchInput {
      margin-top: 8px; 
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
    }
    
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      background-color: #2196F3;
    }
    
    input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }

    /* BTNS */

    .submit {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items:center;
        height: 100%;
        margin: 20px;
        margin-right: 0px;
    }
      
    .btn {
        padding: 10px 20px;
        background-color: #0d6efd;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
      
    .btn:disabled {
        background-color: #6c757d;
        color: #ffffff;
        cursor: not-allowed;
    }
      
    /* select actividad */
    .row{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .selectAct{
      padding: 8px;
      border-radius: 10px;
      border: 1px solid #898989;
    }

    .label {
      color: black;
      font-weight: bold;
      text-align: left;
    }
    .error{
      margin-top: 10px;
      color: rgb(248, 85, 85);
    }