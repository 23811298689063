.containerNotas {
  height: 100vh;
  padding: 5px;
}

.tituloNotas {
  color: #054bdd;
  font-weight: 700;
  margin: 10px 0;
}

/* width */
.containerEntradas::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.containerEntradas::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c7c7c7;
  border-radius: 5px;
}

/* Handle */
.containerEntradas::-webkit-scrollbar-thumb {
  background: #4b4b4b;
  border-radius: 5px;
}

/* Handle on hover */
.containerEntradas::-webkit-scrollbar-thumb:hover {
  background: #807b7b;
}

.containerEntradas {
  height: 13rem;
  border: 2px solid #a9a9a9;
  border-radius: 3px;
  padding: 10px;
  overflow-y: auto;
}
.containerEntradaComentario {
  margin: 10px 0;
  padding: 5px;
  border-radius: 3px;
}
.containerEntradaComentario:nth-child(2n + 1) {
  background-color: #a9a9a9;
}

.containerEntradaComentario > p {
  display: flex;
  flex-direction: column;
}

.containerAgregarEntrada {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerTextareaEntrada {
  width: 80%;
}
.containerTextareaEntrada > p {
  text-align: center;
  padding: 5px 0;
  background-color: #c7c7c7;
  width: 100%;
}
.containerTextareaEntradaError > textarea {
  border: 1px solid #de3323;
}
.containerTextareaEntrada > textarea {
  width: 100%;
  resize: vertical;
  font-family: "Sarabun", sans-serif, "EBGaramond", serif;
  font-size: 14px;
}

.buttonsTextareaEntrada {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.btnEnviarCotejo {
  padding: 9px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #054bdd;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.btnEnviarCotejo:hover {
  background-color: #3d91e6;
  cursor: pointer;
}

.btnEnviarCotejo:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btnAgregarComentario {
  padding: 9px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #198754;
  border: none;
  border-radius: 5px;
}
.btnAgregarComentario:hover {
  background-color: #4ba078;
  cursor: pointer;
}
.btnAgregarComentario:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btnCancelar {
  padding: 9px 10px;
  text-transform: capitalize;
  color: #ffffff;
  background-color: #de3323;
  border: none;
  border-radius: 5px;
}
.btnCancelar:hover {
  background-color: #cf594e;
  cursor: pointer;
}

.modalDiv { 
  display: flex;
  justify-content: end;
}

.spliText{
  word-break: break-all;
  overflow-wrap: break-word;
}