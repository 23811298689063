.container {
    font-family: "Arial", sans-serif;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .inconformidad_title {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      margin: 10px;
      color: #443f3f;
      border-bottom: 1px solid #cccccc;
      padding-bottom: 10px;
      background-color: #f0f0f040;
      font-family: "Sarabun", sans-serif, 'EBGaramond', serif;    
    }

    .subTitle {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: left;
      margin: 20px 0px;
      color: #443f3f;
      font-family: "Sarabun", sans-serif, 'EBGaramond', serif;
    }

    /* header */

    .btn {
      padding: 10px 20px;
      background-color: #0d6efd;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .btn:disabled {
      background-color: #6c757d;
      color: #ffffff;
      cursor: not-allowed;
    }

        /* containers */
        @media (max-width: 880px) {
          .aside {
              display: none;
          }
        }
    
        .container_aside {
          display: flex;
          border: 1px solid #B9B9B9;
          border-style: none dashed none none;
        }
    
        .solicitudContainer {
          width: 100%;
          padding: 1rem;
        } 
    
        .aside {
          width: 30%;
          padding: 1rem;
          border-right: 1px dashed #b9b9b9;
        }  
        
        .aside > p {
            margin: 8px 0;
        }
      
      /* estilo para form */
      
      .column  {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 10px;
      }  

      .instructions{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
        padding: 5px;
        background-color: #b9b9b9;
      }
      
      .label {
        margin-bottom: 8px; 
        font-size: 14px;
        font-weight: bold; 
      }    
      
      .select {
        width: 100%; 
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  /* Accordion */
  .accordion {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
    
  .rowItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;

  }
  
  .content {
    flex-grow: 1;
    text-align: left;
    margin-left: 8px;
  }

  .checkmark{

    margin-left: auto;
    color: #0d6efd;
  }

  .outlined  {
    border: 2px solid blue;
  }
  
  .checkbox {
    margin-left: 8px;
    cursor: pointer;
  }

  .deleteButton {
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
  }
  
