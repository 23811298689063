.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: min(100%, 600px);
  margin: 10px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  text-align: center;
  margin-bottom: 20px;
}

.modalHeaderTitle {
  font-weight: bold;
  text-align: left;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modalContainerActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btnAction {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
}

.btnActionPrimary {
  background-color: #054bdd;
}

.btnActionPrimaryCancel {
  background-color: #7e7e7e;
}

@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    max-height: calc(100vh - 20px);
    padding: 15px;
  }
}
