.container {
    padding: 1rem;
}
.titlePage {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 1rem;
    margin: 10px;
}
.containerParrafo > p {
    margin-left: 1rem;
}
.parrafoInstruccionesConIcon {
    color: #054BDD;
    font-weight: 700;
    margin: 10px 0;
}
.parrafoInstruccionesConIcon > span {
    color: #FFD536;
}

.parrafoInstrucciones, .textoGris {
    line-height: 20px;
    font-size: 16px;
    color: #47464C;
}
.parrafoInstrucciones > span {
    font-weight: bold;
    color: #054BDD;

}

.containerTable {
    margin: 2rem 0;
}
.containerFiltrosTabla {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 3px 0;
}
.containerFiltrosTabla > div {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 50%;
}

.containerButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
}
.containerButtons > button {
    padding: 9px 10px;
    text-transform: capitalize;
    color: #ffffff;
    background-color: #054BDD;
    border: none;
    border-radius: 5px;
}
.containerButtons > button > svg {
    color: #00c2c7;
}
.containerButtons > button:hover {
  background-color: #3d91e6;
  cursor: pointer;
}

.tdNombre {
    text-transform: uppercase;
}
.tdCheckbox {
   text-align: center;
}
.td {
    padding: 4px;
}

.tdTextCenter {
    text-align: center;
}