.restForm {
    margin-top: 1rem;
}


.submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0;
}

.submit input[type="submit"] {
    padding: 8px 10px;
    color: #ffff;
    background-color: #054BDD;
    border-radius: 4px;
}

.submit input[type="submit"]:hover {
    cursor: pointer;
    background-color: #3f5ac5;
}

.submit>p {
    color: #00C0A9;
    margin: 10px 0;
}

.textCenter {
    justify-content: center;
    align-items: center;
    text-align: center;
}