.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: #fff;
    padding: 4px;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    height: 90dvh;
    max-height: max-content;
    
}

.modal-content {
    overflow-y: auto;
    height: 100%;
    max-height: max-content;
    scrollbar-width: thin;
    scrollbar-color: #c1c1c1 #f9f9f9;
    padding: 16px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-form .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.form-group label {
    width: 40%;
    margin-right: 10px;
    font-weight: bold;
}

.form-group .input-group,
.form-group select,
.form-group input {
    width: 60%;
}

.form-group input {
    border: 1px solid #ccc; 
}

.input-group {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between select and input */
    width: 100%;
}

.fixed-width-select {
    flex: 1; /* Adjust width as needed */
}

.fixed-width-input {
    flex: 1; /* Adjust width as needed */
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.close-button,
.reset-button {
    background-color: #f5f5f5;
    color: #333;
}

.submit-button {
    background-color: #007bff;
    color: #fff;
}

.close-button:hover,
.reset-button:hover {
    background-color: #e0e0e0;
}

.submit-button:hover {
    background-color: #0056b3;
}

.modal-form .radio-group {
    display: flex;
    gap: 15px;
    align-items: center;
}

.radio-group label {
    display: flex;
    align-items: center;
    font-weight: normal;
}

.radio-group input[type="radio"] {
    margin-right: 5px;
}

.p-style {
    color: red;
}


@media (max-width: 768px) {
    .modal-container {
        width: 90%; /* Ajustar el ancho en pantallas más pequeñas */
        max-width: 300px; /* Limitar el tamaño máximo */
    }
}

